var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "containerFlexColumn" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              {
                staticClass: "bg-purple border bcWhite padding10 borderRadius5",
              },
              [
                _c("img", {
                  staticClass: "total-icon",
                  attrs: { src: _vm.img_home_order },
                }),
                _c("div", { staticClass: "total-title" }, [
                  _vm._v("今日订单总数"),
                ]),
                _c("div", { staticClass: "total-value" }, [_vm._v("200")]),
              ]
            ),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              {
                staticClass:
                  "bg-purple-light border bcWhite padding10 borderRadius5",
              },
              [
                _c("img", {
                  staticClass: "total-icon",
                  attrs: { src: _vm.img_home_today_amount },
                }),
                _c("div", { staticClass: "total-title" }, [
                  _vm._v("今日销售总额"),
                ]),
                _c("div", { staticClass: "total-value" }, [
                  _vm._v("￥5000.00"),
                ]),
              ]
            ),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              {
                staticClass: "bg-purple border bcWhite padding10 borderRadius5",
              },
              [
                _c("img", {
                  staticClass: "total-icon",
                  attrs: { src: _vm.img_home_yesterday_amount },
                }),
                _c("div", { staticClass: "total-title" }, [
                  _vm._v("昨日销售总额"),
                ]),
                _c("div", { staticClass: "total-value" }, [
                  _vm._v("￥5000.00"),
                ]),
              ]
            ),
          ]),
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "marginTop20 bcWhite borderRadius10 border" },
              [
                _c("div", { staticClass: "padding20 borderBottom fWB" }, [
                  _vm._v("待处理事务"),
                ]),
                _c(
                  "div",
                  { staticClass: "un-handle-content" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "un-handle-item" }, [
                            _c("span", { staticClass: "font-medium" }, [
                              _vm._v("待付款订单"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "color-danger",
                                staticStyle: { float: "right" },
                              },
                              [_vm._v("(10)")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "un-handle-item" }, [
                            _c("span", { staticClass: "font-medium" }, [
                              _vm._v("已完成订单"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "color-danger",
                                staticStyle: { float: "right" },
                              },
                              [_vm._v("(10)")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "un-handle-item" }, [
                            _c("span", { staticClass: "font-medium" }, [
                              _vm._v("待确认收货订单"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "color-danger",
                                staticStyle: { float: "right" },
                              },
                              [_vm._v("(10)")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "un-handle-item" }, [
                            _c("span", { staticClass: "font-medium" }, [
                              _vm._v("待发货订单"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "color-danger",
                                staticStyle: { float: "right" },
                              },
                              [_vm._v("(10)")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "un-handle-item" }, [
                            _c("span", { staticClass: "font-medium" }, [
                              _vm._v("新缺货登记"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "color-danger",
                                staticStyle: { float: "right" },
                              },
                              [_vm._v("(10)")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "un-handle-item" }, [
                            _c("span", { staticClass: "font-medium" }, [
                              _vm._v("待处理退款申请"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "color-danger",
                                staticStyle: { float: "right" },
                              },
                              [_vm._v("(10)")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "un-handle-item" }, [
                            _c("span", { staticClass: "font-medium" }, [
                              _vm._v("已发货订单"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "color-danger",
                                staticStyle: { float: "right" },
                              },
                              [_vm._v("(10)")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "un-handle-item" }, [
                            _c("span", { staticClass: "font-medium" }, [
                              _vm._v("待处理退货订单"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "color-danger",
                                staticStyle: { float: "right" },
                              },
                              [_vm._v("(10)")]
                            ),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "un-handle-item" }, [
                            _c("span", { staticClass: "font-medium" }, [
                              _vm._v("广告位即将到期"),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "color-danger",
                                staticStyle: { float: "right" },
                              },
                              [_vm._v("(10)")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "overview-layout" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "borderRadius10 bcWhite border" }, [
                  _c("div", { staticClass: "padding20 borderBottom fWB" }, [
                    _vm._v("货品总览"),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { padding: "40px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "color-danger overview-item-value",
                              attrs: { span: 6 },
                            },
                            [_vm._v("100")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "color-danger overview-item-value",
                              attrs: { span: 6 },
                            },
                            [_vm._v("400")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "color-danger overview-item-value",
                              attrs: { span: 6 },
                            },
                            [_vm._v("50")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "color-danger overview-item-value",
                              attrs: { span: 6 },
                            },
                            [_vm._v("500")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "font-medium" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "overview-item-title",
                              attrs: { span: 6 },
                            },
                            [_vm._v("已下架")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "overview-item-title",
                              attrs: { span: 6 },
                            },
                            [_vm._v("已上架")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "overview-item-title",
                              attrs: { span: 6 },
                            },
                            [_vm._v("库存紧张")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "overview-item-title",
                              attrs: { span: 6 },
                            },
                            [_vm._v("全部货品")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "bcWhite borderRadius10 border" }, [
                  _c("div", { staticClass: "padding20 borderBottom fWB" }, [
                    _vm._v("用户总览"),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { padding: "40px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "color-danger overview-item-value",
                              attrs: { span: 6 },
                            },
                            [_vm._v("100")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "color-danger overview-item-value",
                              attrs: { span: 6 },
                            },
                            [_vm._v("200")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "color-danger overview-item-value",
                              attrs: { span: 6 },
                            },
                            [_vm._v("1000")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "color-danger overview-item-value",
                              attrs: { span: 6 },
                            },
                            [_vm._v("5000")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "font-medium" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "overview-item-title",
                              attrs: { span: 6 },
                            },
                            [_vm._v("今日新增")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "overview-item-title",
                              attrs: { span: 6 },
                            },
                            [_vm._v("昨日新增")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "overview-item-title",
                              attrs: { span: 6 },
                            },
                            [_vm._v("本月新增")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "overview-item-title",
                              attrs: { span: 6 },
                            },
                            [_vm._v("会员总数")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "marginTop20 bcWhite borderRadius10 border" },
        [
          _c("div", { staticClass: "padding20 borderBottom fWB" }, [
            _vm._v("订单统计"),
          ]),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticStyle: { padding: "20px" } }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#909399", "font-size": "14px" },
                      },
                      [_vm._v("本月订单总数")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#606266",
                          "font-size": "24px",
                          padding: "10px 0",
                        },
                      },
                      [_vm._v("10000")]
                    ),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "color-success",
                          staticStyle: { "font-size": "14px" },
                        },
                        [_vm._v("+10%")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#c0c4cc",
                            "font-size": "14px",
                          },
                        },
                        [_vm._v("同比上月")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticStyle: { "margin-top": "20px" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#909399", "font-size": "14px" },
                      },
                      [_vm._v("本周订单总数")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#606266",
                          "font-size": "24px",
                          padding: "10px 0",
                        },
                      },
                      [_vm._v("1000")]
                    ),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "color-danger",
                          staticStyle: { "font-size": "14px" },
                        },
                        [_vm._v("-10%")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#c0c4cc",
                            "font-size": "14px",
                          },
                        },
                        [_vm._v("同比上周")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticStyle: { "margin-top": "20px" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#909399", "font-size": "14px" },
                      },
                      [_vm._v("本月销售总额")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#606266",
                          "font-size": "24px",
                          padding: "10px 0",
                        },
                      },
                      [_vm._v("100000")]
                    ),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "color-success",
                          staticStyle: { "font-size": "14px" },
                        },
                        [_vm._v("+10%")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#c0c4cc",
                            "font-size": "14px",
                          },
                        },
                        [_vm._v("同比上月")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticStyle: { "margin-top": "20px" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#909399", "font-size": "14px" },
                      },
                      [_vm._v("本周销售总额")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#606266",
                          "font-size": "24px",
                          padding: "10px 0",
                        },
                      },
                      [_vm._v("50000")]
                    ),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "color-danger",
                          staticStyle: { "font-size": "14px" },
                        },
                        [_vm._v("-10%")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#c0c4cc",
                            "font-size": "14px",
                          },
                        },
                        [_vm._v("同比上周")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 20 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      padding: "10px",
                      "border-left": "1px solid #dcdfe6",
                    },
                  },
                  [
                    _c("div", {
                      staticStyle: { width: "100%", height: "300px" },
                      attrs: { id: "chartmainline" },
                    }),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "18%",
            title: "设置密码",
            "append-to-body": "",
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "login-form",
              attrs: {
                model: _vm.form,
                "auto-complete": "on",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "密码" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pwd", $$v)
                      },
                      expression: "form.pwd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loadiing: _vm.btnLoading,
                    type: "primary",
                  },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("提 交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }